.news-container {
  padding: 20px;
  padding-bottom: 50px;
}

.contact-label-news {
  white-space: nowrap;
}

.span-label-news {
  white-space: nowrap;
}

.news-button-section {
  min-width: 225px;
}

.flex-space-lang {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}
