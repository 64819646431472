.food-details-heading p {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #313131;
}

.food-details-heading .MuiButtonBase-root {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #09414e;
  text-transform: capitalize;
}

.restaurant-details-img {
  width: 54px;
  height: 54px;
}

.rest-details-first {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.rest-details-second {
  flex-grow: 14;
  flex-shrink: 1;
  flex-basis: 0%;
}

.rest-details-second p {
  margin-bottom: 0;
}

.rest-id-details {
  margin-bottom: 22px;
}

.contact-label-product {
  margin-bottom: 10px;
}

.news-image-edit.product-image-edit {
  max-width: 100%;
  max-height: 40vh;
}
