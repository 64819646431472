* {
  font-family: "Inter", sans-serif;
}

.footer-main {
  position: fixed;
  bottom: 0;
  max-width: 100%;
  height: 30px;
  background-color: #09414e;
  color: #fff;
}

h6.para-margin {
  font-size: 10px;
  margin-bottom: 0;
}
