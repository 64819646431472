.register {
  margin: auto;
}
.form {
  margin: 0 auto;
  max-width: 50%;
  width: 100%;
}

.form .reg-input {
  margin-bottom: 16px;
}

.right-cntnr {
  align-content: left !important;
  float: left;
}

.rgstr-btn {
  margin-top: 30px !important;
}

.lft-cntnr {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.register-img {
  height: 100vh;
  width: 100%;
}

.register-right-section {
  margin: auto;
}

@media screen and (max-width: 991px) {
  .register-right-section {
    margin-top: 30px;
  }
}
