* {
  font-family: "Inter", sans-serif;
}

.MuiAppBar-root.navbar-header {
  background-color: #09414e;
  position: fixed;
  z-index: 1;
}

.MuiButton-root.delnie-logo {
  margin-right: 26px;
}

.nav-search-bar {
  height: 35px;
  position: relative;
}

.nav-search-bar .MuiInputBase-root {
  width: 100%;
}

.dropdown-grid {
  color: #fff;
}

.apps-dropdown h6 {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center !important;
  letter-spacing: -0.3px;
  color: #f5f5f5;
  margin-left: 14px;
}

.apps-dropdown h6,
.pro-dropdown-btn h6 {
  margin-bottom: 0;
}

.pro-dropdown-btn h6 {
  font-size: 14px;
  margin-left: 9px;
  text-transform: none;
  color: #fff;
  text-decoration: none;
  border-bottom: none;
}

.pro-dropdown-btn::after {
  content: url("../../assets/images/Navbar/dropdown-arrow.svg");
  margin-left: 5px;
}

.nav-search-bar .nav-search-input .MuiInputBase-input {
  padding-left: 1em;
  font-size: 14px;
}

.nav-search-main .nav-search-bar {
  background-color: rgba($color: #ffffff, $alpha: 0.3);
}

.search-end {
  font-size: 14px;
  position: absolute;
  top: 7px;
  bottom: 0;
  right: 14px;
  opacity: 43%;
}

button.demo-customized-btn {
  margin-top: 13px;
}

.notif-profile-section {
  align-items: center;
  margin-left: 2px;
}

.pro-btn-main {
  margin-left: 2px;
}

.pro-btn-main button span {
  font-weight: 400;
}

.pro-acc-btn {
  position: relative;
}

.pro-acc-btn span {
  align-self: flex-end;
  margin-bottom: 6px;
}

.pro-acc-btn span img {
  align-self: flex-end;
  margin-top: 10px;
}

button.pro-nav-btn {
  text-transform: none;
}

p.para-margin {
  margin-bottom: 0;
}

.span-spacing {
  margin-left: 14px;
}

/*Media queries*/
@media (min-width: 600px) {
  .MuiToolbar-root.navbar-height {
    min-height: 60px;
  }
}

@media (max-width: 767px) {
  .nav-search-main {
    width: 25%;
  }
}

@media (max-width: 992px) {
  .MuiButton-root.delnie-logo {
    margin-right: 0px;
  }
  .MuiAppBar-root.navbar-header {
    position: absolute;
  }
}
