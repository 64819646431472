.news-details-container {
  padding: 20px;
  padding-bottom: 50px;
}

.news-details-image {
  object-fit: contain;
  max-width: 100%;
  height: 150px;
}

.news-details-container h5.news-details-content {
  margin-top: 10px;
}

.galleries-image-input {
  opacity: 0;
}

.gallery-album {
  padding: 10px;
  align-items: center;
  // height :'300px';
  margin-top: 20px;
}
.album_card {
  min-height: 280px;
}
.ss {
  margin-left: 20px;
}
.image-input {
  opacity: 0;
  width: 40px;
  text-align: center;
}
.add_image_icon {
  margin-top: 50%;
}
.image_select_input {
  opacity: 0;
  width: 170px;
  text-align: center;
  height: 200px;
  position: absolute;

  margin-left: -112px;
  margin-top: 1px;
}
.add-image {
  background-color: lightgray;
  text-align: center;
  align-items: center;
  padding: 2px 20px;
  height: 200px;
  width: 200px;
}

.dropdown-album-icon {
  color: #000000;
}

.dropdown-album-icon:hover {
  border-radius: 50%;
  background-color: #dddddf;
}

.row.ss.gallery-card-container > div {
  flex: 1;
  flex-grow: 0;
}

.row.ss.gallery-card-container > div .MuiCard-root {
  margin-right: 0 !important;
  width: 250px;
}

.photo-video-switch {
  position: relative;
}

.warning-text-container {
  position: absolute;
  top: 30px;
  right: 40px;
}

h5.warning-text {
  font-size: 12px;
}

.add-image-container .MuiCardContent-root:last-child {
  padding: 22px;
}
