.news-details-container {
  padding: 20px;
  padding-bottom: 50px;
}

.news-details-image {
  object-fit: contain;
  max-width: 100%;
  height: 150px;
}

.news-details-container h5.events-details-content {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.members-details-cards {
  border: 1px solid #707070;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 16px;
}
