.drawer-width {
  max-width: 349px;
}

.MuiDrawer-root .MuiPaper-root {
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiList-root.rightbar-header {
  max-width: 349px;
  padding: 18.05px 86px 22.65px 17px;
}

.MuiPaper-root.MuiDrawer-paper {
  height: calc(100% - 30px);
}

.rightbar-body,
.rightbar-header,
.rightbar-footer,
.rightbar-tab,
.rightbar-sub-body {
  max-width: 349px;
}

.rightbar-header,
.rightbar-tab,
.rightbar-tab .MuiBox-root,
.rightbar-footer {
  background-color: #09414e;
}

.MuiList-root.rightbar-tab {
  padding-bottom: 0;
  padding-top: 0;
}

.MuiList-root.rightbar-body {
  min-height: 278.76px;
  padding-bottom: 0;
  padding-top: 0;
}

.MuiList-root.rightbar-sub-body {
  min-height: 143px;
}

.rightbar-tab .row {
  width: 100%;
}

.rightbar-body {
  background-color: #fff;
}

.rightbar-sub-body {
  background-color: rgba(103, 181, 199, 0.26);
}
