.register-cntr {
  margin-top: 160px;
}

.btn-black {
  background-color: #000 !important;
  color: #fff;
  margin: 20px;
  width: 15%;
  height: 35px;
  border-radius: 3px;
  font-size: medium;
}
.form-field {
  margin: auto;
}
