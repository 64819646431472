.news-details-container {
  padding: 20px;
  padding-bottom: 50px;
}

.news-details-image {
  object-fit: contain;
  max-width: 100%;
  height: 150px;
}

.news-details-container h5.news-details-content {
  margin-top: 10px;
}

.details-img-container {
  max-width: 90%;
  margin: auto;
}
