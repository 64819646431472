.invoices-table thead tr th {
  font-size: 14px;
  font-weight: 500;
}
.table tr > td {
  padding: 0;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0 !important;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-accent-bg: #ededed;
  color: var(--bs-table-striped-color);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fff;
  color: var(--bs-table-striped-color);
}

.thead-main {
  background-color: #ededed;
  border-top: 1px solid #000000;
}

.invoices-table tbody tr {
  vertical-align: middle;
}
.invoices-table tbody tr:hover {
  background-color: #fac8b2;
  cursor: pointer;
}

th.table-headings {
  text-transform: capitalize;
  white-space: nowrap;
}

.invoices-table tbody td.td-small {
  font-size: 12px;
  font-weight: 400;
  color: #222222;
}

.invoices-table tbody td.td-name {
  color: #2d9dcb;
  font-size: 12px;
  font-weight: 500;
}

.invoices-table tbody td.td-status {
  font-size: 12px;
  font-weight: 600;
}

.action-btn {
  margin-right: 13px;
}

.invoices-table-container {
  margin-bottom: 12px;
}

.invoices-table-container {
  overflow-x: auto;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.invoices-table-container tbody tr td,
.invoices-table-container tbody tr span {
  font-size: 12px;
  color: #222222;
  padding: 0px 8px;
}

.content-title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoices-table-container tbody tr td.news-table-images {
  padding-bottom: 16px;
}

.file-upload-button {
  cursor: "pointer";
  display: "inline-flex";
  align-items: "center";
  justify-content: "center";
  padding: "10px 20px";
  color: "white";
  border-radius: "5px";
  border: "none";
  font-size: "16px";
  white-space: "nowrap";
  border: 1px solid #000000;
}

.file-upload-button:hover {
  background-color: #ff6600;
}

/* Media-queries */

@media screen and (max-width: 992px) {
  .invoices-table-container {
    width: 100%;
    overflow-x: auto;
  }
}
