.MuiTablePagination-root {
  display: flex;
  justify-content: flex-start;
}

.MuiTablePagination-root .MuiToolbar-root {
  padding-left: 0;
}

p.MuiTablePagination-selectLabel,
p.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}
