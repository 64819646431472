.invoice-field-values {
  text-transform: capitalize;
}

.fieldsettings-page-size span {
  font-size: 14px;
}

.field-modal-radio {
  color: #09414e !important;
}
