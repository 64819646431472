.news-container {
  padding: 20px;
  padding-bottom: 50px;
}

img.editable-image {
  object-fit: contain;
  max-width: 100%;
  height: 150px;
}

#imageInput {
  opacity: 0;
  width: 10px;
}

.news-image-edit {
  object-fit: contain;
  max-width: 60px;
  max-height: 60px;
}

.MuiInputBase-root.image-input {
  display: none;
}
