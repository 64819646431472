.news-container {
  padding: 20px;
  padding-bottom: 50px;
}

.product-new-heading {
  white-space: nowrap;
}

.boolean-text {
  flex: 2;
}

.radio-div {
  flex: 9;
}

body.MuiDialog-open {
  overflow: hidden !important;
}

.MuiSelect-root[aria-haspopup="true"] {
  overflow: visible;
}

.declaration-container p {
  margin-bottom: 0;
}

.member-image-container div:nth-child(2) {
  flex: 3;
}
