.register {
  margin: auto;
}
.form {
  margin: 0 auto;
  // max-width: 70%;
  // width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.form .reg-input {
  margin-bottom: 20px;
}

.MuiFormGroup-root.register-formgroup {
  max-width: 366px;
  width: 100%;
}

.right-cntnr {
  align-content: left !important;
  float: left;
}

.rgstr-btn {
  margin-top: 30px !important;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.lft-cntnr {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.register-img {
  height: 100vh;
  width: 100%;
}

.register-right-section {
  margin: auto;
  margin-top: 31.5px !important;
}

.name-field,
.email-field,
.password-field,
.org-field {
  background-color: #efefef;
  // width: 22.5911vw !important;
  max-width: 346.99px !important;
}

.name-field .MuiOutlinedInput-notchedOutline,
.email-field .MuiOutlinedInput-notchedOutline,
.password-field .MuiOutlinedInput-notchedOutline,
.org-field .MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.conditions-check-container {
  max-width: 22.59vw;
}

.conditions-check-container span {
  font-family: "Helvetica", sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.MuiButton-root.register-btn {
  margin-top: 5px !important;
  margin-bottom: 32.54px !important;
  background-color: #0f7ca7;
  color: #fff;
  width: 144px;
  height: 35.46px;
  border: none;
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.MuiButton-root.register-btn span {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

@media screen and (max-width: 991px) {
  .register-right-section {
    margin-top: 30px;
  }
}
