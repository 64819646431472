.modal-btn-container {
  max-width: 45%;
  margin: auto;
}

.modal-btn-container button.yes {
  background-color: #ad4518;
}

.modal-btn-container button.no {
  color: #ad4518;
  border-color: #ad4518;
}
