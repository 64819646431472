.login-page {
  height: 100vh;
  margin: auto;
}

.google-icon {
  width: 20px;
  height: 20px;
}

.google-btn {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  background: #fff;
  border: none;
  width: auto;
  margin-bottom: 74px;
  border-radius: 0;
}

.google-btn img {
  width: 16px;
  height: 16px;
  margin-right: 14px;
}

.google-btn span {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.logo-cntr {
  margin-top: 80px;
  align-items: left;
}

.heading {
  margin-top: 78px;
  margin-bottom: 27px;
  font-family: serif;
}

.email-field,
.password-field {
  background-color: #efefef;
  max-width: 346.99px !important;
}

.email-field .MuiOutlinedInput-notchedOutline,
.password-field .MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.form.email-field {
  margin-bottom: 16px;
}

.form.password-field {
  margin-bottom: 27px;
}

.forgot-password {
  width: 22.5911vw;
  margin: auto;
}

.forgot-password a {
  float: right;
  color: #c1145d;
  text-decoration: none;
}

.logo {
  margin-top: -10px;
}

.company-name-first-part,
.company-name-second-part {
  font-family: "Inter", sans-serif;
  color: #c1145d;
  padding-top: 30px;
  // align-items: left;
  font-size: 40px;
}

.company-name-first-part {
  font-weight: 600;
}

.company-name-second-part {
  font-weight: 400;
}

.login-heading {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #c1145d;
}

.sign-up {
  color: #c1145d;
  text-decoration: none;
}

.login-btn {
  margin-top: 5px !important;
  margin-bottom: 32.54px !important;
  background-color: #0f7ca7;
  color: #fff;
  width: 144px;
  height: 35.46px;
  border: none;
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.login-google-option h1 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #797979;
  margin-bottom: 27px;
}

.login-rt-cntnr {
  padding-left: 0;
  padding-right: 0;
}

.des-head {
  text-align: left;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
  color: #fff;
}

.des-1 {
  text-align: left;
  margin-left: 20px;
  margin-bottom: 30px;
  color: #fff;
}

.des-2 {
  text-align: left;
  margin-left: 20px;
  margin-bottom: 30px;
  color: #fff !important;
  text-decoration: none;
}

.a {
  color: #fff !important;
  text-decoration: none;
}
