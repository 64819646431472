.filter-chip {
  margin-right: 8px;
  margin-right: 8px;
  padding: 3px 0px;
  border: solid thin;
  font-size: 10px;
}

.filter-title {
  font-weight: bold;
  background-color: #09414f;
  color: #fff;
  text-transform: capitalize;
  padding: 3px 3px;
}

.filter-option-text{
  text-transform: capitalize;

}

.filter-value {
  font-weight: normal;
  padding: 3px 3px;
}
.filter-close {
  top: 22px;
  right: 18px;
  font-size: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 30px;
}
.filter-close:hover {
  background-color: rgb(191, 181, 181);
}
