.tabswitch-container {
  background-color: #fff;
}

.tabswitch-head button,
.tabswitch-head button.Mui-selected {
  text-transform: capitalize;
}

.tabswitch-head button.Mui-selected {
  color: #b66643 !important;
}

.tabswitch-container {
  font-family: "Inter", sans-serif;
}

/*Media queries */

@media screen and (max-width: 992px) {
  div.tabswitch-container {
    width: auto;
  }
}
