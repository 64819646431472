.tabheader-main {
  // margin-left: 20px;
  padding-top: 21px;
  padding-bottom: 21px;
}

.invoicesheader-top {
  width: 100%;
  margin: auto;
  background-color: #eaeaea;
}

.invoice-details h3,
.invoice-details p {
  margin-bottom: 0;
}

.invoice-master-filter {
  width: 47.7213vw;
}

.invoice-details h3 {
  font-size: 20px;
  font-weight: 600;
  color: #09414e;
  margin-bottom: 8px;
}

.invoice-details p {
  font-size: 14px;
}

.invoice-modify-btns {
  flex-grow: 1;
}

button.invoice-download-btn,
button.invoice-create-btn {
  text-transform: capitalize;
}

button.invoice-download-btn {
  background-color: #b66643;
}

button.invoice-create-btn {
  color: #fff;
  background-color: #b66643;
  border-color: #b66643;
  border: 3px solid #b66643;
}

button.invoice-create-btn img {
  margin-right: 6px;
}

.MuiButtonBase-root.invoice-action-btn {
  text-transform: capitalize;
  color: #000000;
  margin-right: 1.888vw;
}

.MuiButtonBase-root.field-settings-btn {
  padding: 0.3906vw 0;
  min-width: 1.30208vw;
  width: 1.30208vw;
  height: 1.30208vw;
}

button.filter-btn {
  padding: 0 16px;
}

button.filter-btn span {
  color: #09414e;
}

button.invoice-filter-btn,
button.invoice-download-btn,
button.invoice-create-btn {
  height: 35px;
}

button.invoice-create-btn:hover {
  border: 3px solid #b66643;
  color: #b66643;
}

.invoice-create-btn a {
  font-family: "Inter", sans-serif;
  font-size: 0.9115vw;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}

button.invoice-download-btn:hover {
  background-color: #b66643;
}

div.select-main {
  margin: 0;
  width: 137px;
  background-color: rgba(9, 65, 78, 0.12);
}

div.select-inner div {
  padding: 6px 14px;
}

div.select-inner div span {
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #09414e;
}

.settings-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffffff;
  border: 2px solid #000;
  // boxShadow: 24,
  padding: 4px;
}

.invoices-settings-header {
  margin-bottom: 15px;
}

.invoices-settings-header h6 {
  margin-bottom: 0;
}

.field-settings-table {
  max-height: 220px;
  overflow-y: scroll;
}

.MuiFormControl-root.field-settings-search
  .MuiInputBase-root.MuiOutlinedInput-root {
  padding-left: 22px;
}

.fields-modal-btns {
  margin-top: 18px;
}

.fields-modal-btns div {
  width: 168px;
}

.fields-modal-btns button {
  width: 73px;
  text-transform: capitalize;
}

.fields-modal-btns button:first-child {
  background-color: #b66643;
}

.fields-modal-btns button:nth-child(2) {
  border-color: #b66643;
  color: #b66643;
}

/* Media queries */

@media screen and (max-width: 991px) {
  .invoice-details,
  div.select-main {
    margin-bottom: 8px;
  }

  button.invoice-download-btn {
    margin-right: 8px;
  }
}
