.apps-dropdown-main .MuiButton-root:hover {
  color: #db561e;
}

.apps-dropdown-active {
  color: #af4518;
}

.apps-dropdown-main .MuiButton-root {
  border-left: 1px solid #808080;
  border-radius: 0;
}

.apps-dropdown-menu div {
  max-height: 85%;
}

.apps-dropdown-menu .MuiPaper-root {
  background-color: #09414e;
  color: #fff;
  top: 59px !important;
  border-radius: 2px;
  border: 1px solid #808080;
}

.apps-dropdown-menu .MuiPaper-root ul {
  padding: 0;
}

.apps-dropdown-left,
.apps-dropdown-right {
  text-align: left;
  overflow-y: auto;
  padding: 40px;
}

.apps-dropdown-left {
  border-right: 1px solid #808080;
  max-width: 300px;
  min-width: 300px;
}

.apps-dropdown-left p:hover {
  cursor: pointer;
  color: #db561e;
}

.apps-dropdown-right {
  max-width: 600px;
  min-width: 600px;
}
